.Center {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 10px;
}

.wordCenter {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 500px;
}

.Image {
  border-radius: 8px;

  height: 600px;
  margin-top: 20px;
}

.divStyle {
  margin-top: 40px;
  margin-left: 20px;
}
